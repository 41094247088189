<template>
  <header class="topbar">
    <nav class="navbar top-navbar navbar-expand-lg navbar-dark">
      <div class="navbar-header">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a
          class="nav-toggler waves-effect waves-light d-block d-lg-none"
          href="javascript:void(0)"
          ><i class="ti-menu ti-close"></i
        ></a>
        <!-- -------------------------------------------------------------- -->
        <!-- Logo -->
        <!-- -------------------------------------------------------------- -->
        <a class="navbar-brand" href="javascript:void(0)">
          <!-- Logo icon -->
          <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img
              src="assets/images/logo-icon.png"
              alt="homepage"
              class="dark-logo"
            />
            <!-- Light Logo icon -->
            <img
              src="assets/images/logo-light-icon.png"
              alt="homepage"
              class="light-logo"
            />
          </b>
          <!--End Logo icon -->
        </a>
        <!-- -------------------------------------------------------------- -->
        <!-- End Logo -->
        <!-- -------------------------------------------------------------- -->
        <!-- -------------------------------------------------------------- -->
        <!-- Toggle which is visible on mobile only -->
        <!-- -------------------------------------------------------------- -->
        <a
          class="topbartoggler d-block d-lg-none waves-effect waves-light"
          href="javascript:void(0)"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ><i class="ti-more"></i
        ></a>
      </div>
      <!-- -------------------------------------------------------------- -->
      <!-- End Logo -->
      <!-- -------------------------------------------------------------- -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <!-- -------------------------------------------------------------- -->
        <!-- toggle and nav items -->
        <!-- -------------------------------------------------------------- -->
        <ul class="navbar-nav me-auto">
          <!-- This is  -->
          <!-- <li class="nav-item"> <a
                                  class="nav-link sidebartoggler d-none d-md-block waves-effect waves-dark"
                                  href="javascript:void(0)"><i class="ti-menu"></i></a> </li> -->
        </ul>
        <!-- -------------------------------------------------------------- -->
        <!-- Right side toggle and nav items -->
        <!-- -------------------------------------------------------------- -->
        <ul class="navbar-nav justify-content-end">
          <!-- -------------------------------------------------------------- -->
          <!-- Comment -->
          <!-- -------------------------------------------------------------- -->
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark"
              href="#"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-message"></i>
              <div class="notify">
                <span class="heartbit"></span> <span class="point"></span>
              </div>
            </a>
            <my-notifications/>
          </li> -->
          <!-- -------------------------------------------------------------- -->
          <!-- End Comment -->
          <!-- -------------------------------------------------------------- -->
          <!-- -------------------------------------------------------------- -->
          <!-- Language -->
          <!-- -------------------------------------------------------------- -->
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
              href=""
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="flag-icon flag-icon-in"></i
            ></a>
            <language-menu/>
          </li> -->
          <!-- -------------------------------------------------------------- -->
          <!-- Profile -->
          <!-- -------------------------------------------------------------- -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle waves-effect waves-dark"
              href="#"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="assets/images/users/1.jpg"
                alt="user"
                width="30"
                class="profile-pic rounded-circle"
              />
            </a>
            <user-menu />
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";
import MyNotifications from '@/components/MyNotifications.vue';
import LanguageMenu from '@/components/LanguageMenu.vue';
export default {
  components: { UserMenu, MyNotifications, LanguageMenu },
};
</script>

<style>
</style>
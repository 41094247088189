/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - Clean Vue.js Dashboard Admin Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
	primary: '#7367F0',
	success: '#28C76F',
	danger: '#EA5455',
	warning: '#FF9F43',
	dark: '#1E1E1E',
}

import Vue from 'vue'

// CONFIGS
const themeConfig = {
	theme: 'light',						// options[String]: 'light'(default), 'dark', 'semi-dark'
	sidebarCollapsed: true,			// options[Boolean]: true, false(default)
	navbarColor: "#fff",				// options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
	navbarType: "floating",				// options[String]: floating(default) / static / sticky / hidden
	footerType: "static",				// options[String]: static(default) / sticky / hidden
	routerTransition: 'zoom-fade',		// options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
	disableCustomizer: true,			// options[Boolean]: true, false(default)
	hideScrollToTop: false,				// options[Boolean]: true, false(default)
	disableThemeTour: true,					// options[Boolean]: true, false(default)
	primary: '#7367F0',
	success: '#28C76F',
	danger: '#FF4C51',
	warning: '#FF9F43',
	secondary: '#808390',
	info: '#00BAD1',
	dark: '#1E1E1E',
	cardBg1: '#fff',//F68C1F orange
	cardBg2: '#ebf4f5',//034DA2 blue
	successImg: "/images/celebration.gif",
	errorImg: "/images/error.gif",
	infoImg: "/images/info.gif",
	deleteImg: "/images/delete.gif",
}

export default themeConfig

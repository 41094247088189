<template>
  <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up">
    <a class="dropdown-item" href="#"
      ><i class="flag-icon flag-icon-in"></i> English</a
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
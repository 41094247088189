<template>
  <li class="sidebar-item">
    <a
      href="javascript:void(0)"
      class="sidebar-link has-arrow waves-effect waves-dark"
      aria-expanded="false"
    >
      <i class="mdi mdi-" :class="menu.MenuIcon" :style="menu.MenuStyle"></i>
      <span class="hide-menu"> {{ menu.MenuName }} </span>
    </a>
    <ul aria-expanded="false" class="collapse first-level">
      <template v-for="(menuItem, index) in menu.Children">
        <child-sub-menu-item
          :key="index"
          v-if="menuItem.ChildrenCount == 0"
          :menu="menuItem"
          :menuId="index"
        >
        </child-sub-menu-item>
      </template>
    </ul>
  </li>
</template>

<script>
import ChildSubMenuItem from "@/components/ChildSubMenuItem.vue";
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
  components: { ChildSubMenuItem },
};
</script>

<style lang="scss" scoped>
</style>
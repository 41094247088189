import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "blank" },
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "blank" },
    component: () => import("@/views/RegisterPage.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      reload: true,
    },
    component: () => import("@/views/dashboard/DashboardPage.vue"),
  },
  {
    path: "/vendor/list",
    name: "vendor list",
    component: () => import("@/views/vendor/VendorList.vue"),
  },
  {
    path: "/my-profile",
    name: "My Profile",
    meta: {
      reload: true,
    },
    component: () => import("@/views/MyProfile.vue"),
  },
  {
    path: "/change-password",
    name: "Change Password",
    meta: {
      reload: true,
    },
    component: () => import("@/views/ChangePassword.vue"),
  },

  // products

  {
    path: "/my-products",
    name: "my list",
    component: () => import("@/views/product/MyProductList.vue"),
  },
  {
    path: "/product-list",
    name: "product list",
    component: () => import("@/views/product/ProductListView.vue"),
  },
  {
    path: "/product-create",
    name: "product create",
    component: () => import("@/views/product/ProductCreateView.vue"),
  },
  {
    path: "/product-edit",
    name: "product edit",
    component: () => import("@/views/product/ProductEditView.vue"),
  },
  {
    path: "/category-list",
    name: "category list",
    component: () => import("@/views/category/CategoryListView.vue"),
  },
  {
    path: "/tag-list",
    name: "tag list",
    component: () => import("@/views/tag/TagListView.vue"),
  },
  {
    path: "/attribute-list",
    name: "attribute list",
    component: () => import("@/views/attribute/AttributeListView.vue"),
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log({ to });
    console.log({ from });
    console.log({ savedPosition });
    return { x: 0, y: 0 };
  },
});

export default router;

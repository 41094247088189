<template>
  <div class="dropdown-menu dropdown-menu-end user-dd animated flipInY">
    <div class="d-flex no-block align-items-center p-3 bg-info text-white mb-2">
      <div class="">
        <img
          src="assets/images/users/1.jpg"
          alt="user"
          class="rounded-circle"
          width="60"
        />
      </div>
      <div class="ms-2">
        <h4 class="mb-0 text-white">{{ User.Username }}</h4>
        <p class="mb-0">
          <i data-feather="phone" class="feather-sm text-white me-1 ms-1"></i>
          {{ User.MobileNo }}
        </p>
      </div>
    </div>
    <router-link to="/my-profile" class="dropdown-item">
      <i data-feather="user" class="feather-sm text-info me-1 ms-1"></i> My
      Profile
    </router-link>
    <router-link to="/change-password" class="dropdown-item">
      <i data-feather="lock" class="feather-sm text-info me-1 ms-1"></i>
      Change Password
    </router-link>
    <!-- <a class="dropdown-item" href="#"
      ><i data-feather="credit-card" class="feather-sm text-info me-1 ms-1"></i>
      My Balance</a
    >
    <a class="dropdown-item" href="#"
      ><i data-feather="mail" class="feather-sm text-success me-1 ms-1"></i>
      Inbox</a
    >
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" href="#"
      ><i data-feather="settings" class="feather-sm text-warning me-1 ms-1"></i>
      Account Setting</a
    > -->
    <div class="dropdown-divider"></div>
    <!-- <a class="dropdown-item" href="#"
                ><i
                  data-feather="log-out"
                  class="feather-sm text-danger me-1 ms-1"
                ></i>
                Logout</a
              > -->
    <v-btn class="dropdown-item" @click.prevent="LogoutUser">
      <i data-feather="log-out" class="feather-sm text-danger me-1 ms-1"></i>
      Logout
    </v-btn>
    <!-- <div class="dropdown-divider"></div>
              <div class="pl-4 p-2">
                <a href="#" class="btn d-block w-100 btn-info rounded-pill"
                  >View Profile</a
                >
              </div> -->
  </div>
</template>

<script>
import common from "@/Common.vue";

export default {
  mixins: [common],
  data() {
    return {
      User: {},
    };
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      var user = this.$cookies.get("user");
      console.log({ user });
      this.User = user;
    },
  },
  beforeMount() {
    if (!this.$cookies.isKey("user")) {
      console.log("your session is expired");
      this.$router.push("/");
    } else {
      this.refreshPageData();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
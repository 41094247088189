<template>
  <div id="app">
    <v-app>
      <v-main>
        <component :is="layout">
          <router-view />
        </component>
      </v-main>
    </v-app>
  </div>
</template>

<script>
const default_layout = "default";
export default {
  name: "app",
  components: {},
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style></style>
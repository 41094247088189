<template>
  <div class="main-wrapper">
    <div class="row auth-wrapper gx-0">
      <div class="col-lg-3 bg-info auth-box-2 on-sidebar">
        <div class="h-100 d-flex align-items-center justify-content-center">
          <div class="row justify-content-center text-center">
            <div class="col-md-7 col-lg-12 col-xl-9">
              <div>
                <span class="db"
                  ><img src="assets/images/logo-light-icon.png" alt="logo"
                /></span>
              </div>
              <h2 class="text-white mt-4 fw-light">
                Build
                <span class="font-weight-medium">Fast & Responsive</span> Web
                Apps with Vaagai
              </h2>
              <p class="op-5 text-white fs-4 mt-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 d-flex align-items-center justify-content-center">
        <slot />
      </div>
    </div>
    <footer-content />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <footer class="footer text-center">
    All Rights Reserved by KATTADAM. Powered by Vaagai Tecknowledge
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

const app = createApp(App)
app.config.globalProperties.productionTip = false

import TopBar from '@/components/TopBar.vue'
app.component("top-bar", TopBar);

import LeftSidebar from '@/components/LeftSidebar.vue'
app.component("left-sidebar", LeftSidebar);

import FooterContent from '@/components/FooterContent.vue'
app.component("footer-content", FooterContent);

// For Custom Layout components
import DefaultLayout from "@/layouts/DefaultLayout.vue";
app.component("default-layout", DefaultLayout);

import BlankLayout from "@/layouts/BlankLayout.vue";
app.component("blank-layout", BlankLayout);

import axios from 'axios'
import VueAxios from 'vue-axios'
app.config.globalProperties.$http = axios
app.config.globalProperties.CancelToken = axios.CancelToken;

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
app.config.globalProperties.$toast = toast

import VueCookies from 'vue3-cookies'
app.use(VueCookies, {
  expireTimes: "1d",
  path: "/",
  domain: "",
  secure: false,
  sameSite: "None"
});

import { createI18n } from 'vue-i18n'
import i18nData from './i18n.json'

const i18n = createI18n({
  locale: 'en',
  messages: i18nData
})

app
  .use(i18n)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');

<template>
  <div class="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up">
    <ul class="list-style-none">
      <li>
        <div class="border-bottom rounded-top py-3 px-4">
          <div class="mb-0 font-weight-medium fs-4">Notifications</div>
        </div>
      </li>
      <li>
        <div
          class="message-center notifications position-relative"
          style="height: 230px"
        >
          <!-- Message -->
          <a
            href="javascript:void(0)"
            class="message-item d-flex align-items-center border-bottom px-3 py-2"
          >
            <span class="btn btn-light-danger text-danger btn-circle">
              <i data-feather="link" class="feather-sm fill-white"></i>
            </span>
            <div class="w-75 d-inline-block v-middle ps-3">
              <h5 class="message-title mb-0 mt-1 fs-3 fw-bold">Luanch Admin</h5>
              <span
                class="fs-2 text-nowrap d-block time text-truncate fw-normal text-muted mt-1"
                >Just see the my new admin!</span
              >
              <span class="fs-2 text-nowrap d-block subtext text-muted"
                >9:30 AM</span
              >
            </div>
          </a>
          <!-- Message -->
          <a
            href="javascript:void(0)"
            class="message-item d-flex align-items-center border-bottom px-3 py-2"
          >
            <span class="btn btn-light-success text-success btn-circle">
              <i data-feather="calendar" class="feather-sm fill-white"></i>
            </span>
            <div class="w-75 d-inline-block v-middle ps-3">
              <h5 class="message-title mb-0 mt-1 fs-3 fw-bold">Event today</h5>
              <span
                class="fs-2 text-nowrap d-block time text-truncate fw-normal text-muted mt-1"
                >Just a reminder that you have event</span
              >
              <span class="fs-2 text-nowrap d-block subtext text-muted"
                >9:10 AM</span
              >
            </div>
          </a>
          <!-- Message -->
          <a
            href="javascript:void(0)"
            class="message-item d-flex align-items-center border-bottom px-3 py-2"
          >
            <span class="btn btn-light-info text-info btn-circle">
              <i data-feather="settings" class="feather-sm fill-white"></i>
            </span>
            <div class="w-75 d-inline-block v-middle ps-3">
              <h5 class="message-title mb-0 mt-1 fs-3 fw-bold">Settings</h5>
              <span
                class="fs-2 text-nowrap d-block time text-truncate fw-normal text-muted mt-1"
                >You can customize this template as you want</span
              >
              <span class="fs-2 text-nowrap d-block subtext text-muted"
                >9:08 AM</span
              >
            </div>
          </a>
          <!-- Message -->
          <a
            href="javascript:void(0)"
            class="message-item d-flex align-items-center border-bottom px-3 py-2"
          >
            <span class="btn btn-light-primary text-primary btn-circle">
              <i data-feather="users" class="feather-sm fill-white"></i>
            </span>
            <div class="w-75 d-inline-block v-middle ps-3">
              <h5 class="message-title mb-0 mt-1 fs-3 fw-bold">Pavan kumar</h5>
              <span
                class="fs-2 text-nowrap d-block time text-truncate fw-normal text-muted mt-1"
                >Just see the my admin!</span
              >
              <span class="fs-2 text-nowrap d-block subtext text-muted"
                >9:02 AM</span
              >
            </div>
          </a>
        </div>
      </li>
      <li>
        <a
          class="nav-link border-top text-center text-dark pt-3"
          href="javascript:void(0);"
        >
          <strong>Check all notifications</strong>
          <i class="fa fa-angle-right"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <div id="main-wrapper">
      <top-bar />
      <left-sidebar />
      <div class="page-wrapper">
        <div class="container-fluid">
          <slot />
        </div>
        <footer-content />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style>
</style>